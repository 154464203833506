import clsx from 'clsx';

import headshot from '../images/jennifer.jpg';

const HomeSection = ({ rowClassNames, columnClassNames, children }) => (
  <div className={clsx('row text-center', rowClassNames)}>
    <div className={columnClassNames}>
      <img
        src={headshot}
        alt="Jennifer Virden headshot"
        className="img-fluid headshot"
      />
      {children}
      <div>
        <a href="mailto:jennifer@austinhaus.com">Email</a>{' '}
        |{' '}
        <a href="tel: +1 512-658-3468">Call</a>
      </div>
    </div>
  </div>
);

export default HomeSection;
