import emailSignature from '../images/jenniferEmailSignature.jpg';

const BusinessCardSignature = () => (
  <div>
    <div className="biz-card-wrapper">
      <img
        src={emailSignature}
        alt="Jennifer Virden business card"
        className="img-fluid hoverable"
      />
    </div>
  </div>
);

export default BusinessCardSignature;
