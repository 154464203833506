import logo from './logo.svg';

import BusinessCardSignature from './components/BusinessCardSignature';
import Disclaimers from './components/Disclaimers';
import HomeSection from './components/HomeSection';

import './App.css';

const App = () => (
  <div className="App">
    <header>
      <img
        id="logo"
        src={logo}
        alt="AustinHaus Realty and Restorations logo"
      />
    </header>
    <main>
      { /* Phone Layout */ }
      <HomeSection
        rowClassNames="d-block d-md-none"
        columnClassNames="col-xs-8 col-sm-12"
      >
        <BusinessCardSignature />
      </HomeSection>

      { /* Desktop Layout */ }
      <HomeSection
        rowClassNames="d-none d-md-block"
        columnClassNames="big-picture"
      >
        <BusinessCardSignature />
      </HomeSection>
    </main>
    <Disclaimers />
  </div>
);

export default App;
