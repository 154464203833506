const Disclaimers = () => (
  <div id="disclaimers">
    <div class="d-block d-sm-none">
      <div>
        <a
          href="https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-2.pdf"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          TREC Consumer Protection Notice
        </a>
      </div>
      <div>
        <a
          href="https://www.trec.texas.gov/sites/default/files/pdf-forms/IABS%201-0.pdf"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          TREC Information About Brokerage Services
        </a>
      </div>
    </div>
    <div class="d-none d-sm-block">
      <a
        href="https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-2.pdf"
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        TREC Consumer Protection Notice
      </a>{' '}
      |{' '}
      <a
        href="https://www.trec.texas.gov/sites/default/files/pdf-forms/IABS%201-0.pdf"
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        TREC Information About Brokerage Services
      </a>
    </div>
  </div>
);

export default Disclaimers;
